.main {
    min-height: 100vh;
}

.header {
    background-image: 
        linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #000004 100%), 
        url('../images/hero-header.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    height: 84.5rem;
    padding-left: 20.75rem;
    padding-right: 20.75rem;
    padding-top: 28.1rem;

    @media (hover: none) {
        background-attachment: initial;
    }

    @include respond($bp-two) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        padding-left: 4rem;
        padding-right: 4rem;
        height: 70rem;
    }
}

.about {
    padding: 15rem 4rem;

    @include respond($bp-two) {
        padding-top: 12.5rem;
        padding-bottom: 6.25rem;
    }

    @include respond($bp-six) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.projects {
    padding: 15rem 4rem;

    @include respond($bp-two) {
        padding-top: 12.5rem;
        padding-bottom: 6.25rem;
    }

    @include respond($bp-six) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

}

.contact {
    padding: 15rem 4rem;

    @include respond($bp-two) {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
    }

    @include respond($bp-six) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.footer {
    background-color: $background-primary-colour;
    padding: 3.5rem;
}