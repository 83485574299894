.nav {
    position: fixed;
    z-index: 15;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    padding: 3rem 4rem;
    transition: .5s ease-in;

    &__logo {
        object-fit: contain;
        width: 13rem;
        height: auto;
        margin-right: 4rem;
        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(96%) contrast(107%);

        @include respond($mobile-bp) {
            display: none;
        }
    }

    &__link-box {
        list-style: none;
        display: flex;
        align-items: center;

        @include respond($mobile-bp) {
            display: none;
        }
    }

    &__item {
        text-decoration: none;
        position: relative;
        z-index: 30;

        &:not(:last-child) {
            margin-right: 2rem;
        }
        
    }

    &__link:link,
    &__link:visited {
        color: inherit;
        font-size: 1.8rem;
        font-weight: 700;
        text-decoration: none;
        transition: .5s;
    }

    &__animation {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1rem;
        width: 100%;
        height: .3rem;
        background-color: $link-text-colour; 
        transform: scaleX(0);
        transition: transform 0.5s;
    }

    &__mobile-menu {
        display: none;

        @include respond ($mobile-bp) {
            display: block;
            position: relative;
            z-index: 30;
            filter: invert(97%) sepia(99%) saturate(0%) hue-rotate(293deg) brightness(115%) contrast(100%);
            width: 4rem;
            margin-top: .2rem;
        }
    }

    &__mobile-menu-icon {
        width: 100%;
    }

    &__mobile-menu-overlay {
        position: absolute;
        z-index: 10;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        padding-top: 10.9rem;
        background-color: rgba(0, 6, 15, 0.5);
        touch-action: none;
    }

    .mobile-menu-overlay-enter {
        background-color: transparent;

        .nav__mobile-link-box {
            background-color: transparent;
            color: transparent;
            border-top: solid transparent 1px;
        }
    }
    
    .mobile-menu-overlay-enter-active {
        background-color: rgba(0, 6, 15, 0.5);
        transition: .5s ease-in;

        .nav__mobile-link-box {
            background-color: $background-primary-colour;
            color: #fff;
            border-top: solid #fff 1px;
            transition: .5s ease-in;
        }
    }
    
    .mobile-menu-overlay-exit {
        background-color: rgba(0, 6, 15, 0.5);

        .nav__mobile-link-box {
            background-color: $background-primary-colour;
            color: #fff;
            border-top: solid #fff 1px;
        }
    }
    
    .mobile-menu-overlay-exit-active {
        background-color: transparent;
        transition: .5s ease-in;

        .nav__mobile-link-box {
            background-color: transparent;
            color: transparent;
            border-top: solid transparent 1px;
            transition: .5s ease-in;
        }
    }

    &__mobile-link-box {
        position: absolute;
        z-index: 10;
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        border-top: solid #fff 1px;
        background-color: $background-primary-colour;
    }

    &__mobile-item {
        text-decoration: none;
        padding: 2.25rem 4.3rem;   
    }

    &__mobile-link:link,
    &__mobile-link:visited {
        color: inherit;
        font-size: 2.2rem;
        font-weight: 700;
        text-decoration: none;
    }

    &__social-box {
        list-style: none;
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    &__social-icon {
        width: 3.25rem;        
    }
}

.header-content {
    position: relative;
    
    &__title-prefix {
        font-size: 3rem;
        font-weight: 500;
        color: $header-accent-colour;
        margin-bottom: 2rem;

        @include respond ($bp-three) {
            font-size: 2.5rem;
        }
    }

    &__title {
        font-size: 7.5rem;
        font-weight: 900;

        @include respond ($bp-three) {
            font-size: 5.4rem;
        }
    }

    &__sub-title {
        font-size: 7.5rem;
        font-weight: 900;
        color: $link-text-colour;

        @include respond ($bp-three) {
            font-size: 5.4rem;
        }
    }
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 2.5rem;
    }

    &__text {
        font-size: 2.2rem;
        color: #757575;
        margin-bottom: 3.5rem;
    }

    &__box {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    &__filter-cont {
        display: inline-flex;
        list-style: none;
        background-color: #000b34;
        padding: 1.25rem 1.25rem;
        border-radius: 50em;
        margin-bottom: 2rem;
    }

    &__filter-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        padding: 1.5rem 3rem;
        border-radius: 10rem;
        transition: .3s ease-in;
        text-align: center;
        cursor: pointer;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: rgb(253, 71, 102);
            }
        }

        &:not(:last-child) {
            margin-right: .5rem;
        }

        @include respond($bp-six) {
            font-weight: 500;
        }
    }

    &__info-scrolling-wrapper {
        position: relative;
        width: 59.2rem;
        height: 20rem;
        overflow: hidden;
        max-width: 100%;
    }

    &__info-box {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        max-width: 100%;
        transition: .4s ease-in;
        padding-top: 2rem;
    }
}

.front-end {
    min-width: 100%;
    padding: 0 1rem;

    &__box {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        min-height: 20rem;
        max-height: 20rem;
    }

    &__skill {
        width: 48%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

        &:not(:last-child) {
            margin-bottom: 1.8rem;
        }

        &:nth-child(odd) {
            margin-right: 2rem;
        }
    }

    &__text {
        font-size: 2rem;
        font-weight: 700;
    }

    &__icon {
        width: 3rem;
        transition: .1s;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes rotate{
    from{
        transform: translateY(-.5rem) scale(.88) rotate(0deg)
        
      }
      to{
        transform: translateY(-.5rem) scale(.88) rotate(360deg)
      }
  }

.back-end {
    min-width: 100%;
    padding: 0 1rem;

    &__box {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        min-height: 10rem;
        max-height: 10rem;
    }

    &__skill {
        width: 48%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

        &:not(:last-child) {
            margin-bottom: 1.8rem;
        }

        &:nth-child(odd) {
            margin-right: 2rem;
        }
    }

    &__text {
        font-size: 2rem;
        font-weight: 700;
    }

    &__icon {
        width: 3rem;
        transition: .1s;
    }

}

.experience {
    min-width: 100%;
    min-height: 20rem;
    padding: 0 1rem;

    &__box {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 57.2rem;
    }

    &__job-info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        flex: 0 0 100%;
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    &__job-title-box {
        display: flex;
        align-items: center;
    }

    &__role {
        margin-bottom: .5rem;
        font-weight: 800;
    }

    &__company {
        margin-right: 1rem;
    }

    &__icon {
        width: 3.5rem;
        transition: .1s;
    }
}

.education {
    min-width: 100%;
    min-height: 20rem;
    padding: 0 1rem;

    &__box {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 57.2rem;
        width: 100%;
    }

    &__item {
        flex: 0 0 100%;
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }

    &__info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        margin-bottom: .5rem;
        font-weight: 800;
    }

    &__school-info-box {
        display: flex;
        align-items: center;
    }

    &__school {
        margin-right: 1rem;
    }

    &__icon {
        width: 3.5rem;
        transition: .1s;
        filter: invert(97%) sepia(99%) saturate(0%) hue-rotate(293deg) brightness(115%) contrast(100%);
    }
}

.projects-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 2.5rem;
    }

    &__text {
        font-size: 2.2rem;
        color: #757575;
        margin-bottom: 8rem;
    }
}

.project-container {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    max-width: 100%;

    @include respond($bp-one) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond ($bp-five) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__item-box {
        position: relative;
        width: 39rem;
        height: 53rem;
        padding: 0 1.5rem;
        border-radius: 1rem;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100%;
        transition: .5s ease-out;

        @include respond ($bp-five) {
            aspect-ratio: 1 / 1.2;
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-position: 50% -1rem;
                background-size: 103%;
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #fff;
        transition: .5s ease-out;
    }

    &__text-box {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: .5s;
    }

    &__type {
        font-size: 2rem;
        color: #D3D3D3;

        @include respond($bp-five) {
            font-size: 2.6rem
        }
    }

    &__title {
        font-size: 4rem;
        font-weight: 800;
        text-align: center;
        margin-bottom: 1rem;

        @include respond ($bp-five) {
            font-size: 5rem;
        }
    }

    &__description {
        font-size: 2rem;
        font-weight: 500;
        color: #D3D3D3;
        text-align: center;
        opacity: 0;
        min-height: 5.8rem;
    }

    &__background-gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: .89rem;
        background-image: linear-gradient(180deg,rgba(15,15,15,0),rgba(15,15,15,.75));
        transition: .75s;
        opacity: 1;
    }

    &__hover-background-gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: .89rem;
        opacity: 0;
        transition: .75s;
        background-image: linear-gradient(180deg,transparent,$background-primary-colour);
    }
}

.contact-container {
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    width: 65rem;
    margin-left: auto;
    margin-right: auto;

    @include respond($bp-five) {
        width: 90%;
    }

    @include respond($bp-seven) {
        width: 100%;
    }

    &__header {
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 2.5rem;
    }

    &__phone-number {
        font-size: 1.8rem;
        margin-right: auto;
        color: #757575;
        margin-bottom: .5rem;
    }

    &__phone-number-text {
        font-size: 2rem;
        font-weight: 600;
    }

    &__email {
        font-size: 1.8rem;
        margin-right: auto;
        color: #757575;
        margin-bottom: 5rem;
    }

    &__email-text {
        font-size: 2rem;
        font-weight: 700;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
        outline: none;
        color: #fff;
        font-family: inherit;
        font-size: 1.35rem;
        background-color: transparent;
        border: .2rem solid #757575;
        border-radius: .7rem;
        padding: 2rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }

    &__message {
        resize: none;
        min-height: 15.6rem;
    }

    &__missing-info {
        border: solid .1rem #fff;
        padding: 2.1rem;
    }

    &__animate {
        animation: shake .15s 2 linear;
    }

    @keyframes shake {
        0% {
          transform: translate(.3rem, 0);
        }
        50% {
          transform: translate(-.3rem, 0);
        }
        100% {
          transform: translate(0, 0);
        }
      }

    &__send-button {
        outline: none;
        border: none;
        color: #fff;
        background-color: $link-text-colour;
        margin-right: auto;
        font-weight: 800;
        padding: 1.5rem 4rem;
        border-radius: 10rem;
        font-family: inherit;
        transition: .1s ease-in;
        cursor: pointer;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                scale: 1.1;
            }
        }
        
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;

    &__text {
        font-size: 2rem;
        font-weight: 700;
    }
}